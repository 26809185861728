var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"product-mini-carousel-item flex--row-start-start"},[_c('div',{staticClass:"product-mini-carousel-item__image-holder"},[_c('div',{staticClass:"product-mini-carousel-item__image-sizer"},[(_vm.getPackshot)?_c('bleach-image',{key:_vm.product.displayTitle,staticClass:"product-mini-carousel-item__image",attrs:{"src":_vm.getPackshot.original_secure_url,"default-size":[120, 120],"alt":(_vm.getPackshot.metadata && _vm.getPackshot.metadata.alt) ||
            _vm.product.displayTitle + ' product image'}}):_vm._e()],1)]),_c('div',{staticClass:"product-mini-carousel-item__content"},[_c('div',{staticClass:"product-mini-carousel-item__title text--w-demi text--left text--clr-black mb-8",class:[_vm.mini ? 'text-zd-cart-upsell' : 'text-commerce-intro'],attrs:{"itemprop":"name"}},[_vm._v(" "+_vm._s(_vm.product.displayTitle)+" ")]),_c('meta',{attrs:{"itemprop":"description","content":_vm.product.description}}),_c('div',{staticClass:"product-mini-carousel-item__button-holder"},[_c('EcomButton',{staticClass:"product-mini-carousel-item__cta",attrs:{"mini":"","product":_vm.product,"price":_vm.product.price},on:{"click":() =>
            _vm.ADD_TO_CART({
              items: [{ product: _vm.product, quantity: 1 }],
              passive: false,
              openCart: false
            })}},[_vm._v(" "+_vm._s(_vm.UI_ELEMENT("cta_add_to_cart_mini"))+" ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }